<template>
  <div class="position-relative">
    <div class="chart-loading-container">
      <canvas :id="id"></canvas>

      <div class="chart-loading">
        <div
          class="spinner-border mr-2"
          style="width: 1rem; height: 1rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    type: {
      required: false,
    },
  },

  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;
      var myChart = new this.$Chart(ctx, {
        type: this.type || "bar",
        data: {
          labels: [1, 2, 3, 4, 5, 6, 8, 9],
          datasets: [
            {
              label: "loading",
              data: [80, 10, 30, 60, 5, 20, 8, 9],
              fill: false,
            },
          ],
        },

        maintainAspectRatio: true,
        resposive: true,
        options: {
          layout: {
            padding: {
              top: 0,
              right: 0,
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
          legend: {
            display: false,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.chart-loading-container {
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  canvas {
    max-height: 300px;
  }
}
.chart-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(245, 245, 245, 0.281);
  height: 100%;
  width: 100%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container {
  // margin: auto;
  height: 200px;
  width: 200px;
  aspect-ratio: 1;
}
</style>
